import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Page from '../../../containers/DraggerGame/Result'
import Seo from '../../../containers/Seo'
import xiahai from '../../../quizes/xiahai-flower/data.json'

const Game = (props) => {
  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`)
  return (
    <>
      <Seo.Flower />
      <Page
        {...props}
        data={data}
        d={xiahai}
      />
    </>
  )
}

export default Game
