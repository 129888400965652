import React, { useMemo, useEffect } from 'react'
import {
  HStack,
  Center,
  Stack,
  Container,
} from "@chakra-ui/react"
import find from 'lodash/find'
import { Link, navigate } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { getSign } from 'horoscope';

import Box from '../../../components/Box'
import Text from '../../../components/Text'
import Button from '../../../components/Button'

import { Media, responsive } from '../../../components/ThemeProvider/theme'

import Footer from '../Footer'

const Title = ({ text, bg, ...props }) => (
  <Box.Relative py="0.75rem" background={bg || "linear-gradient(90deg, transparent 0%, #717f01 25%, #717f01 75%, transparent 100%)"} {...props}>
    <Text.NotoSerif
      letterSpacing={responsive('0.125rem', '0.25rem')}
      textAlign="center"
      fontSize={responsive('1.375em', '1.625em')}
      lineHeight={1}
      color="white"
    >{text}
    </Text.NotoSerif>
  </Box.Relative>
)

const CTAS = [
  {
    desc: '每朵花都有獨特的美麗與價值，用特有種花桌布提醒自己，不要忘記生活中的美好，到島民集合註冊成為島民，獲得每個月不同的花桌布，一起叢心出花。',
    img: <StaticImage layout='fullWidth' src="ads-1.png" alt="特有花圖" />,
    textColor: 'flower.text',
    color: 'flower.buttonBg',
    button: '點我去島民集合',
    href: 'https://url.relab.cc/hi_register_flowersmission_0107'
  },
  {
    desc: '還想要更靠近愛情嗎？\n透過花語籤詩了解你的愛情觀之外，你也可以到台北霞海城隍廟官方授權【你的在線月老】，體驗線上參拜求籤！',
    img: <StaticImage layout='fullWidth' src="ads-2.png" alt="線上求籤" />,
    color: 'flower.lightRed',
    button: '開啟線上求籤',
    href: 'https://url.relab.cc/moom_entrance_flowertest'
  },
]

const Result = ({ location: { state, pathname }, data: { site: { siteMetadata } }, d }) => {
  const path = /\/q\/([^/]+)/g.exec(pathname)?.[1]
  const { answers, questions, results, month, day } = (state ?? {})
  const [leftBin, rightBin, correctCount] = useMemo(() => answers?.reduce((bins, a, i) => {
    const q = questions[i]
    const isCorrect = q.answer === a.ans
    bins[a.ans < 0 ? 0 : 1].push({
      ...q,
      isCorrect,
    })
    if (isCorrect && q.id < 19) {
      bins[2].anxiety = bins[2].anxiety + 1
    }
    if (isCorrect && q.id > 18) {
      bins[2].avoid = bins[2].avoid + 1
    }
    return bins
  }, [[], [], { anxiety: 0, avoid: 0 }]) ?? [], [answers, questions])
  const tier = useMemo(() => {
    return find(results, r => {
      return correctCount.anxiety >= r.anxiety[0] && correctCount.anxiety <= r.anxiety[1] && correctCount.avoid >= r.avoid[0] && correctCount.avoid <= r.avoid[1]
    })
  }, [correctCount, answers, results])
  const type = useMemo(() => find(tier?.types, t => t.months.includes(getSign({ month, day }))), [tier])
  const gameStart = pathname.replace('result/', '')
  const pageUrl = `${siteMetadata.siteUrl}${gameStart}`
  useEffect(() => {
    if (!answers) navigate(gameStart)
  }, [])
  if (!answers) return null
  return (
    <Box minH="full" maxW={'480px'} mx="auto" bg={`${d.bg}.400`}>
      <Box pb="1em">
        <GatsbyImage image={getImage(type?.image)} />
      </Box>
      <Box px="2.5em">
        <Text.NotoSerif
          pb="1rem"
          borderBottom="1px solid lightGray"
          color="flower.text"
          whiteSpace={'pre-wrap'}
          textAlign={'center'}
          letterSpacing={'0.125rem'}
          fontSize={responsive('1.25em', '1.5em')}
        >
          {type?.text}
        </Text.NotoSerif>
        <Text
          fontSize={responsive('0.9375em', '1.125em')}
          letterSpacing={'0.0625em'}
          lineHeight={1.8}
          color="flower.darkGray"
          mb="1.5em"
          mt="1.25em"
          textAlign="justify"
        >
          {type?.desc}
        </Text>
        <Box fontFamily="Noto Serif TC" px="5%" mb="1.5em">
          <Button
            fontSize={responsive('1.25em', '1.5em')}
            bg="flower.text"
            _hover={{
              bg: 'flower.text'
            }}
            color="white"
            width="full"
            borderRadius="full"
            letterSpacing="0.125rem"
            py="0.875rem"
            height="auto"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`}
          >
            分享測驗
          </Button>
          <Button
            as={Link}
            mt="1em"
            fontSize={responsive('1.25em', '1.5em')}
            bg="flower.text"
            _hover={{
              bg: 'flower.text'
            }}
            color="white"
            width="full"
            borderRadius="full"
            letterSpacing="0.125rem"
            py="0.875rem"
            height="auto"
            to="/q/xiahai-flower/"
          >
            再玩一次
          </Button>
        </Box>
      {/* <AnswerDetail oColor={d.oColor} xColor={d.xColor} my="8" leftBin={leftBin} rightBin={rightBin} /> */}
      </Box>
      <Box color="flower.darkGray" pt="2.375em" pb="2.5em" px="1.25em" bg="flower.anaBg">
        <Title text="屬於你的愛情觀分析" />
        <Box px="1.25em">
          <Box pt="2.25em">
            <Text.NotoSerif color={'flower.text'} fontSize={responsive('1.25em', '1.5em')} lineHeight={1.6} whiteSpace={'pre-wrap'}>『{tier.analysis}』</Text.NotoSerif>
            <Text mt="1.125em" textAlign={'justify'} lineHeight={1.8} fontSize={responsive('0.9375em', '1.125em')}>{tier.analysisDesc}</Text>
          </Box>
          <Box pt="1.125em">
            <Text.NotoSerif color={'flower.text'} fontSize={responsive('1.125em', '1.5em')} lineHeight={1.6} whiteSpace={'pre-wrap'}>{type?.analysisII}</Text.NotoSerif>
            <Text mt="1.125em" textAlign={'justify'} lineHeight={1.8} fontSize={responsive('0.9375em', '1.125em')}>{type?.analysisDescII}</Text>
          </Box>
        </Box>
      </Box>
      <Box pt="2.375em" pb="2.5em" px="1.25em">
        <Title text="讓你在愛情中綻放的秘訣" />
        <Box px="1.25em">
          {tier.knack.map((d, i) => (
            <Box key={i} pt="1.875em">
              <Text fontFamily={"Noto Serif TC"} fontWeight={900} color="flower.text" letterSpacing="0.125rem" fontSize={responsive('1.375em', '1.625em')} lineHeight="1.75">
                {i + 1}.{d.title}
              </Text>
              <Text textAlign={"justify"} mt="0.875rem" lineHeight={1.8} fontSize={responsive('0.9375em', '1.125em')}>{d.desc}</Text>
            </Box>
          ))}
        </Box>
      </Box>
      <Box fontFamily="Noto Serif TC" color="flower.darkGray" pt="2.375em" pb="2.5em" px="2.5em" bg="flower.anaBg">
        {CTAS.map((d, i) => (
          <Box mt={i && '4em'} key={i}>
            <Text fontSize={responsive('1em', '1.25em')} fontWeight={900} whiteSpace={'pre-wrap'} lineHeight={1.75} color={d.textColor || d.color}>
              {d.desc}
            </Text>
            <Box my="1.5em">{d.img}</Box>
            <Box px="14%">
              <Button
                fontSize={responsive('1.25em', '1.5em')}
                bg={d.color}
                _hover={{
                  bg: d.color
                }}
                color="white"
                width="full"
                borderRadius="full"
                letterSpacing="0.125rem"
                py="0.875rem"
                height="auto"
                href={d.href}
              >
                {d.button}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
      <Footer partner={path} />
    </Box>
  )
}

export default Result
