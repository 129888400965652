import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const partners = [
  {
    id: 'xiahai-flower',
    to: '/q/xiahai-flower/',
    label: '台北霞海城隍廟',
    field: '特有種花愛情籤詩顧問',
    logo: <StaticImage alt="台北霞海城隍廟" src="./logos/xiahai.png" />,
    img: <StaticImage src="../../images/xiahai-flower/fb.png" />,
    isLive: true,
  },
  {
    id: 'giloo',
    label: 'Giloo',
    href: 'https://gift-exchange.relab.cc/giloo/',
    img: <StaticImage src="../../images/giloo/Facebook分享圖 1200x630.jpg" />,
    logo: <StaticImage alt="Giloo 紀實影音" src="./logos/giloo.png" />,
    field: '紀錄片測驗',
    isLive: true,
  },
  {
    id: 'plainlaw',
    label: '法律白話文運動',
    href: 'https://gift-exchange.relab.cc/plainlaw/',
    field: '法律考題',
    logo: <StaticImage alt="法律白話文運動" src="./logos/plainlaw.png" />,
    img: <StaticImage src="../../images/plainlaw/relab-01.png" />,
    isLive: true,
  },
  {
    id: 'bonne-chance',
    href: 'https://gift-exchange.relab.cc/bonne-chance/',
    label: '上上芊營養顧問公司',
    field: '營養考題',
    logo: <StaticImage alt="上上芊食尚顧問" src="./logos/bonne-chance.png" />,
    img: <StaticImage src="../../images/bonne-chance/上上芊 cover.png" />,
    isLive: true,
  },
  {
    id: 'niusnews',
    label: '妞新聞',
    field: '考題',
    href: 'https://www.niusnews.com/event/pinkhugparty2021',
    logo: <StaticImage alt="NIUSnews妞新聞" src="./logos/niusnews.png" />,
    img: <StaticImage src="../../images/niusnews/122001.jpg" />,
    isLive: true,
  },
  {
    id: 'justfont',
    href: 'https://gift-exchange.relab.cc/justfont/',
    label: 'justfont',
    img: <StaticImage src="../../images/justfont/fb-cover-w-logo.png" />,
    logo: <StaticImage alt="justfont" src="./logos/justfont.png" />,
    field: '字型考題',
    isLive: true,
  },
]

export default partners
