import React from 'react'
import {
  Text,
  Image,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '../../components/Box';
// import Link from '../../components/Link';
import Flex from '../../components/Flex';
import ReButton from '../../components/Button';
import Container from '../../components/Container';
import { responsive } from '../../components/ThemeProvider/theme';

import Quizes from './Quizes'
import partners from './partners'
import GiftTitle from './GiftTitle';

// const Title = props => (
//   <Box bg="black" color="white" mx={responsive('-1em', '-2em')} py="0.75em" px="1.5em">
//     <Heading as="h3" size={`md`} pb="0.125em" {...props} />
//   </Box>
// )

const posts = {
  justfont:'https://url.relab.cc/relabpost-justfont',
  giloo:'https://url.relab.cc/relabpost-giloo',
  'bonne-chance':'https://url.relab.cc/relabpost-bc',
  plainlaw:'https://url.relab.cc/relabpost-plainlawme',
  xiahai:'https://url.relab.cc/relabpost-tpecitygod',
}

const Footer = ({ partner , ...props }) => {
  const thePartner = partners.find((p) => p.id === partner)
  return (
    <Box {...props}>
      <Container maxWidth={480} py="2em" bg="white" borderRadius="2em 2em 0 0">
        <Stack textAlign="center" spacing="6" alignItems={`center`}>
          <GiftTitle>製作團隊</GiftTitle>
          <HStack spacing={2} justifyContent={`center`} fontSize={responsive('0.75em', '1em')}>
            <Box>
              {thePartner?.logo ?? <Image src={`https://via.placeholder.com/300x100/FFFFFF/000000?text=LOGO`} />}
              <Text>『{thePartner?.field}』</Text>
            </Box>
            <Text>×</Text>
            <Box>
              <StaticImage src="./logos/relab.png" alt="RE:LAB" />
              <Text>『好好玩測驗模組工具』</Text>
            </Box>
          </HStack>
          <Box pt="1.5em">
            <Flex alignItems={'center'}>
              <Text>感謝</Text>
              <Box px="0.25em">
                <StaticImage src="./logos/Hello-islander.png" alt="島民集合" />
              </Box>
              <Box flex={1}>提供特有種花語圖</Box>
            </Flex>
            <Flex mt="1em" alignItems={'center'}>
              <Text>感謝</Text>
              <Box px="0.25em">
                <StaticImage src="./logos/god-of-marriage.png" alt="在線月老" />
              </Box>
              <Box textAlign={'right'} flex={1}>協助企劃及推廣</Box>
            </Flex>
          </Box>
          <Box py="2" />
          <GiftTitle>十週年品牌好朋友交換禮物活動</GiftTitle>
          <Stack spacing={4} mx="auto" px="3.5em">
            <Text textAlign="justify" fontSize={responsive('sm', 'md')}>
              愛研究溝通的資訊設計團隊 RE:LAB 十歲了！今年我們開發了一款測驗工具，以此和六個好朋友換到了許多酷東西！
            </Text>
            <Box>
              <ReButton
                fontSize="1.25em"
                href={posts[partner] || 'https://www.facebook.com/ReLAB.cc/posts/4669077049817486'}
                colorScheme="blue"
                onClick={() => window.gtag("event", "button-click", { name: `${partner}-follow` })}
              >追蹤活動</ReButton>
            </Box>
          </Stack>
          <Box py="2" />
          <GiftTitle>看看你還想玩哪一個</GiftTitle>
        </Stack>
        <Quizes partner={partner} my="2" />
      </Container>
    </Box>
  )
}

export default Footer
